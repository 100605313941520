<template>
  <div class="index">
    <body data-spy="scroll" data-target=".site-navbar-target" data-offset="300">
      <section id="home-section" class="hero">
        <div class="text-privacy" style="padding: 2em 4em">
          感謝您使用本公司之產品，為了落實法令遵循及尊重您的權益，本公司依據個人資料保護法（以下稱「個資法」）之規定，向
          您告知本公司依法蒐集、處理、利用、國際傳輸您的個人資料時，必需告知的各款事項，請您務必詳閱：
          <h3>
            一、蒐集之目的(下列代號係參照法務部公告之「個人資料保護法之特定目的及個人資料之類別」，代號及項目名稱嗣後如經法務部公告變更，亦隨同變更之)
          </h3>
          <ul>
            <li>040行銷</li>
            <li>063非公務機關依法定義務所進行個人資料之蒐集處理及利用</li>
            <li>069契約、類似契約或其他法律關係事務</li>
            <li>090消費者、客戶管理與服務</li>
            <li>091消費者保護</li>
            <li>111票券業務</li>
            <li>123貿易推廣及管理</li>
            <li>135資（通）訊服務</li>
            <li>148網路購物及其他電子商務服務</li>
            <li>152廣告或商業行為管理</li>
            <li>154徵信</li>
            <li>157調查、統計與研究分析</li>
            <li>176其他自然人基於正當性目的所進行個人資料之蒐集處理及利用</li>
            <li>181其他經營合於營業登記項目或組織章程所定之業務</li>
          </ul>
          <h3>二、個人資料之類別</h3>
          姓名；年齡；性別；身分證統一編號；出生年月日；戶籍地址；通訊地址；教育程度；國籍；住（居）所電話號碼；行動電話；智慧型手機應用軟體用戶識別碼；傳真號碼；電子郵件地址；護照；居留證明文件；駕駛執照；健保卡；其他證照；職業；任職公司之名稱、電話及地址；收入資料及其他任何得以直接或間接方式識別個人之資料等。
          <h3>三、個人資料利用之期間、地區、對象及方式</h3>
          <ul>
            <li>
              期間：個人資料蒐集之特定目的存續期間/依相關法令規定或契約約定之保存年限(如：商業會計法)/本公司因執行業務所必須之保存期間。
            </li>
            <li>
              地區：本國、未受中央目的事業主管機關限制之國際傳輸個人資料之接收者所在地、本公司業務委外機構所在地、與本公司有業務往來之機構營業處所所在地。
            </li>
            <li>
              對象：本公司、本公司集團公司或與本公司或前述公司因業務需要訂有契約關係或業務往來之機構（含共同行銷、合作推廣業務等）或顧問（如律師）、業務委外機構、未受中央目的事業主管機關限制之國際傳輸個人資料之接收者、依法有調查權機關或金融監理機關。
            </li>
            <li>方式：以自動化機器或其他非自動化之利用方式。</li>
          </ul>
          <h3>
            四、依據個資法第三條規定，台端就本公司保有台端之個人資料得行使下列權利
          </h3>
          <ul>
            <li>
              得向本公司查詢、請求閱覽或請求製給複製本，而本公司依法得酌收必要成本費用。
            </li>
            <li>得向本公司請求補充或更正，惟依法台端應為適當之釋明。</li>
            <li>
              得向本公司請求停止蒐集、處理或利用及請求刪除，惟依法本公司因執行業務所必須者，得不依台端請求為之。
            </li>
          </ul>
          <h3>
            五、您同意本公司有權修訂本告知事項，並同意本公司於修訂後，得以言詞、書面、電話、簡訊、電子郵件、傳真、電子文件或其他足以使您知悉或可得知悉之方式，告知您修訂要點。
          </h3>
        </div>
      </section>

      <footer class="ftco-footer ftco-section" style="padding: 1em 0">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <p>
                <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                Copyright &copy;{{ curYear }} All rights reserved | This
                template is made with
                <i class="icon-heart color-danger" aria-hidden="true"></i> by
                <a href="https://colorlib.com" target="_blank">Colorlib</a>
                <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
              </p>
            </div>
          </div>
        </div>
      </footer>

      <!-- loader -->
      <div id="ftco-loader" class="show fullscreen" ref="loading_show">
        <svg class="circular" width="48px" height="48px">
          <circle
            class="path-bg"
            cx="24"
            cy="24"
            r="22"
            fill="none"
            stroke-width="4"
            stroke="#eeeeee"
          />
          <circle
            class="path"
            cx="24"
            cy="24"
            r="22"
            fill="none"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke="#F96D00"
          />
        </svg>
      </div>
    </body>
  </div>
</template>

<script>
export default {
  name: 'Privacy Policy',
  data: function () {
    return {
      curYear: new Date().getFullYear(),
      name: '',
      message: '',
      email: '',
      contact_phone_number: '',
    }
  },
  props: {
    data: {
      name: String,
      contact_phone_number: String,
      message: String,
      email: String,
    },
  },
  methods: {},
  head: {
    title: () => {
      return {
        inner: '高禾瓦創意數位有限公司',
      }
    },
    meta: [],
    script: [],
    link: [
      {
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800,900',
      },
      { rel: 'stylesheet', href: '/digilab/css/open-iconic-bootstrap.min.css' },
      { rel: 'stylesheet', href: '/digilab/css/animate.css' },
      { rel: 'stylesheet', href: '/digilab/css/owl.carousel.min.css' },
      { rel: 'stylesheet', href: '/digilab/css/owl.theme.default.min.css' },
      { rel: 'stylesheet', href: '/digilab/css/magnific-popup.css' },
      { rel: 'stylesheet', href: '/digilab/css/aos.css' },
      { rel: 'stylesheet', href: '/digilab/css/ionicons.min.css' },
      { rel: 'stylesheet', href: '/digilab/css/flaticon.css' },
      { rel: 'stylesheet', href: '/digilab/css/icomoon.css' },
      { rel: 'stylesheet', href: '/digilab/css/style.css' },
    ],
  },
}
</script>
