import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import Privacy from '../views/Privacy.vue'
import VueHead from 'vue-head'

Vue.use(VueHead)
Vue.use(VueRouter)

const routes = [
  // {
  //       path: '/',
  //       name: 'home',
  //       component: Home
  //   },
  {
    path: '/',
    name: 'index',
    component: Index,
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
  },
  // ,
  //   {
  //       path: '/about',
  //       name: 'about',
  //       // route level code-splitting
  //       // this generates a separate chunk (about.[hash].js) for this route
  //       // which is lazy-loaded when the route is visited.
  //       component: () =>
  //           import ( /* webpackChunkName: "about" */ '../views/About.vue')
  //   }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
