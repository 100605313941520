<template>
    <div class="index">
        <body data-spy="scroll" data-target=".site-navbar-target" data-offset="300">
            <nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light site-navbar-target" id="ftco-navbar">
                <div class="container">
                    <a class="navbar-brand" href="http://gaohowa.com">gao<span>howa</span></a>
                    <button class="navbar-toggler js-fh5co-nav-toggle fh5co-nav-toggle" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="oi oi-menu"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="ftco-nav">
                        <ul class="navbar-nav nav ml-auto">
                        <li class="nav-item"><a href="#home-section" class="nav-link"><span>首頁</span></a></li>
                        <li class="nav-item"><a href="#services-section" class="nav-link"><span>服務項目</span></a></li>
                        <li class="nav-item" hidden><a href="#projects-section" class="nav-link"><span>專案經歷</span></a></li>
                        <li class="nav-item"><a href="#about-section" class="nav-link"><span>關於我們</span></a></li>
                        <li class="nav-item" hidden><a href="#testimony-section" class="nav-link"><span>Testimony</span></a></li>
                        <li class="nav-item" hidden><a href="#blog-section" class="nav-link"><span>Blog</span></a></li>
                        <li class="nav-item"><a href="#contact-section" class="nav-link"><span>聯繫</span></a></li>
                        </ul>
                    </div>
                </div>
            </nav>
            <section id="home-section" class="hero">
                <h3 class="vr">Welcome to Gaohowa</h3>
                <div class="home-slider js-fullheight owl-carousel">
                <div class="slider-item js-fullheight">
                    <div class="overlay"></div>
                    <div class="container-fluid p-0">
                    <div class="row d-md-flex no-gutters slider-text js-fullheight align-items-center justify-content-end" data-scrollax-parent="true">
                        <div class="one-third order-md-last img js-fullheight" style="background-image:url(digilab/images/bg_5.jpg);">
                            <div class="overlay"></div>
                        </div>
                        <div class="one-forth d-flex js-fullheight align-items-center ftco-animate" data-scrollax=" properties: { translateY: '70%' }">
                            <div class="text">
                                <span class="subheading">Welcome to the Gaohowa</span>
                                <h1 class="mb-4 mt-3">您還在找尋「<span>開發App</span>」嗎？</h1>
                                <p>給您的服務踏上行動裝置讓使用者為您展開行動吧！</p>
                                
                                <p><a href="#services-section" class="btn btn-primary px-5 py-3 mt-3">了解更多</a></p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="slider-item js-fullheight">
                    <div class="overlay"></div>
                    <div class="container-fluid p-0">
                    <div class="row d-flex no-gutters slider-text js-fullheight align-items-center justify-content-end" data-scrollax-parent="true">
                        <div class="one-third order-md-last img js-fullheight" style="background-image:url(digilab/images/bg_2.jpg);">
                            <div class="overlay"></div>
                        </div>
                        <div class="one-forth d-flex js-fullheight align-items-center ftco-animate" data-scrollax=" properties: { translateY: '70%' }">
                            <div class="text">
                                <span class="subheading">Welcome to the Gaohowa</span>
                                <h1 class="mb-4 mt-3">打造最佳<span>成效</span>與<span>體驗</span>的網站</h1>
                                <p>我們致力達到最佳的使用者體驗</p>
                                
                                <p><a href="#services-section" class="btn btn-primary px-5 py-3 mt-3">了解更多</a></p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <section class="ftco-section ftco-no-pb ftco-no-pt ftco-services bg-light" id="services-section">
                <div class="container">
                    <div class="row no-gutters">
                    <div class="col-md-4 ftco-animate py-5 nav-link-wrap">
                        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link px-4 active" id="v-pills-1-tab" data-toggle="pill" href="#v-pills-1" role="tab" aria-controls="v-pills-1" aria-selected="true" ref="service-tab1"><span class="mr-3 flaticon-ideas"></span> 網站開發</a>

                        <a class="nav-link px-4" id="v-pills-2-tab" data-toggle="pill" href="#v-pills-2" role="tab" aria-controls="v-pills-2" aria-selected="false" ref="service-tab2"><span class="mr-3 flaticon-flasks"></span> 手機應用程式</a>

                        <a class="nav-link px-4" id="v-pills-3-tab" data-toggle="pill" href="#v-pills-3" role="tab" aria-controls="v-pills-3" aria-selected="false" ref="service-tab3"><span class="mr-3 flaticon-analysis"></span> 企業系統規劃</a>

                        <a class="nav-link px-4" id="v-pills-4-tab" data-toggle="pill" href="#v-pills-4" role="tab" aria-controls="v-pills-4" aria-selected="false" ref="service-tab4"><span class="mr-3 flaticon-web-design"></span> Hardware & Sortware 整合</a>

                        <a class="nav-link px-4" id="v-pills-5-tab" data-toggle="pill" href="#v-pills-5" role="tab" aria-controls="v-pills-5" aria-selected="false" ref="service-tab5"><span class="mr-3 flaticon-ux-design"></span> 雲端伺服器技術與建置</a>

                        <a class="nav-link px-4" id="v-pills-6-tab" data-toggle="pill" href="#v-pills-6" role="tab" aria-controls="v-pills-6" aria-selected="false" hidden><span class="mr-3 flaticon-innovation"></span> IoT</a>

                        <a class="nav-link px-4" id="v-pills-7-tab" data-toggle="pill" href="#v-pills-7" role="tab" aria-controls="v-pills-7" aria-selected="false" hidden><span class="mr-3 flaticon-idea"></span> Creative</a>
                        </div>
                    </div>
                    <div class="col-md-8 ftco-animate p-4 p-md-5 d-flex align-items-center">
                        
                        <div class="tab-content pl-md-5" id="v-pills-tabContent">

                        <div class="tab-pane fade show active py-5" id="v-pills-1" role="tabpanel" aria-labelledby="v-pills-1-tab">
                            <span class="icon mb-3 d-block flaticon-ideas"></span>
                            <h2 class="mb-4">網站開發</h2>
                            <p>提供網站設計、建置、客製等解決方案，我們能依照您的需求打造網頁。無論是形象官網、電子商務、活動網站等我們都能量身定做。</p>
                            <p hidden><a href="#" class="btn btn-primary px-4 py-3">Learn More</a></p>
                        </div>

                        <div class="tab-pane fade py-5" id="v-pills-2" role="tabpanel" aria-labelledby="v-pills-2-tab">
                            <span class="icon mb-3 d-block flaticon-flasks"></span>
                            <h2 class="mb-4">手機應用程式</h2>
                            <p>我們精通Android、iOS應用程式開發，以原生技術建置最流暢的應用程式，並且重視使用者體驗，設計合理合適的UI/UX，讓您的產更能發揮核心效果，並發揮成效。</p>
                            <p hidden><a href="#" class="btn btn-primary px-4 py-3">Learn More</a></p>
                        </div>

                        <div class="tab-pane fade py-5" id="v-pills-3" role="tabpanel" aria-labelledby="v-pills-3-tab">
                            <span class="icon mb-3 d-block flaticon-analysis"></span>
                            <h2 class="mb-4">企業系統規劃</h2>
                            <p>提供企業完整的解決方案，面對各種企業欲解決的問題，我們提供最專業的建議與技術支援，並因應企業的環境、系統介接、資料導入等等。</p>
                            <p>除此之外，我們也能提供完整的教育訓練、文件撰寫與保固服務，使企業在後續的運作與使用上能夠減少各種可能造成的成本與壓力。</p>
                            <p hidden><a href="#" class="btn btn-primary px-4 py-3">Learn More</a></p>
                        </div>

                        <div class="tab-pane fade py-5" id="v-pills-4" role="tabpanel" aria-labelledby="v-pills-4-tab">
                            <span class="icon mb-3 d-block flaticon-web-design"></span>
                            <h2 class="mb-4">Hardware & Software 整合</h2>
                            <p>我們具備了硬體、軟體介接整合技術擴展資訊化範圍，有效的串接兩端資訊呈現與控制，為產品、專案提供更完整的解決方案。</p>
                            <p hidden><a href="#" class="btn btn-primary px-4 py-3">Learn More</a></p>
                        </div>

                        <div class="tab-pane fade py-5" id="v-pills-5" role="tabpanel" aria-labelledby="v-pills-5-tab">
                            <span class="icon mb-3 d-block flaticon-ux-design"></span>
                            <h2 class="mb-4">雲端伺服器技術與建置</h2>
                            <p>實體主機與雲端伺服器皆能建置與服務，主流的雲端伺服器皆有許多的服務經驗。</p>
                            <p>除此之外，因應相關專案需求提供建議與資訊，亦提供伺服器架構規劃與整合服務，快速部署靈活變更架構。</p>
                            <p hidden><a href="#" class="btn btn-primary px-4 py-3">Learn More</a></p>
                        </div>

                        <div class="tab-pane fade py-5" id="v-pills-6" role="tabpanel" aria-labelledby="v-pills-6-tab" hidden>
                            <span class="icon mb-3 d-block flaticon-innovation"></span>
                            <h2 class="mb-4">IoT</h2>
                            <p></p>
                            <p><a href="#" class="btn btn-primary px-4 py-3">Learn More</a></p>
                        </div>

                        <div class="tab-pane fade py-5" id="v-pills-7" role="tabpanel" aria-labelledby="v-pills-7-tab" hidden>
                            <span class="icon mb-3 d-block flaticon-idea"></span>
                            <h2 class="mb-4">Creative Solution</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt voluptate, quibusdam sunt iste dolores consequatur</p>
                            <p>Inventore fugit error iure nisi reiciendis fugiat illo pariatur quam sequi quod iusto facilis officiis nobis sit quis molestias asperiores rem, blanditiis! Commodi exercitationem vitae deserunt qui nihil ea, tempore et quam natus quaerat doloremque.</p>
                            <p><a href="#" class="btn btn-primary px-4 py-3">Learn More</a></p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section class="ftco-section-2 img" style="background-image: url(digilab/images/bg_3.jpg);">
                    <div class="container">
                        <div class="row d-md-flex justify-content-end">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12">
                                        <a class="services-wrap ftco-animate">
                                            <div class="icon d-flex justify-content-center align-items-center">
                                                <span class="ion-ios-arrow-back"></span>
                                                <span class="ion-ios-arrow-forward"></span>
                                            </div>
                                            <h2>最多技術支援</h2>
                                            <p>Objective-C、C、C++、Java、Javascript、Swift、Python、PHP、Html、CSS、XCode、Android Studio、Laravel、Node.js、Express.js、.Net Core、ASP.Net MVC、Angular...</p>
                                        </a>
                                        <a class="services-wrap ftco-animate">
                                            <div class="icon d-flex justify-content-center align-items-center">
                                                <span class="ion-ios-arrow-back"></span>
                                                <span class="ion-ios-arrow-forward"></span>
                                            </div>
                                            <h2>完整專案流程</h2>
                                            <p>從前期第一次需求訪談、需求整理、專案規劃、文件編制與時程安排，到開發期的開發、交付與驗收測試，直至上線、結案與保固，每個階段都能提供給您完整的資訊掌握專案進度。</p>
                                        </a>
                                        <a href="#" class="services-wrap ftco-animate" hidden>
                                            <div class="icon d-flex justify-content-center align-items-center">
                                                <span class="ion-ios-arrow-back"></span>
                                                <span class="ion-ios-arrow-forward"></span>
                                            </div>
                                            <h2>Online Marketing</h2>
                                            <p>Even the all-powerful Pointing has no control about the blind.</p>
                                        </a>
                                        <a href="#" class="services-wrap ftco-animate" hidden>
                                            <div class="icon d-flex justify-content-center align-items-center">
                                                <span class="ion-ios-arrow-back"></span>
                                                <span class="ion-ios-arrow-forward"></span>
                                            </div>
                                            <h2>24/7 Help &amp; Support</h2>
                                            <p>Even the all-powerful Pointing has no control about the blind.</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="ftco-section ftco-project" id="projects-section" hidden>
                    <div class="container px-md-5">
                        <div class="row justify-content-center pb-5">
                    <div class="col-md-12 heading-section text-center ftco-animate">
                        <span class="subheading">專案經歷</span>
                        <h2 class="mb-4">成功案例</h2>
                        <p hidden>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia</p>
                    </div>
                    </div>
                        <div class="row">
                            <div class="col-md-12 testimonial">
                        <div class="carousel-project owl-carousel">
                            <div class="item">
                                <div class="project">
                                            <div class="img">
                                                <img src="digilab/images/project-1.jpg" class="img-fluid" alt="Colorlib Template">
                                                <div class="text px-4">
                                                    <h3><a href="#">Work Name</a></h3>
                                                    <span>Web Design</span>
                                                </div>
                                            </div>
                                        </div>
                            </div>
                            <div class="item">
                                <div class="project">
                                            <div class="img">
                                                <img src="digilab/images/project-2.jpg" class="img-fluid" alt="Colorlib Template">
                                                <div class="text px-4">
                                                    <h3><a href="#">Work Name</a></h3>
                                                    <span>Web Design</span>
                                                </div>
                                            </div>
                                        </div>
                            </div>
                            <div class="item">
                                <div class="project">
                                            <div class="img">
                                                <img src="digilab/images/project-3.jpg" class="img-fluid" alt="Colorlib Template">
                                                <div class="text px-4">
                                                    <h3><a href="#">Work Name</a></h3>
                                                    <span>Web Design</span>
                                                </div>
                                            </div>
                                        </div>
                            </div>
                            <div class="item">
                                <div class="project">
                                            <div class="img">
                                                <img src="digilab/images/project-4.jpg" class="img-fluid" alt="Colorlib Template">
                                                <div class="text px-4">
                                                    <h3><a href="#">Work Name</a></h3>
                                                    <span>Web Design</span>
                                                </div>
                                            </div>
                                        </div>
                            </div>
                            <div class="item">
                                <div class="project">
                                            <div class="img">
                                                <img src="digilab/images/project-5.jpg" class="img-fluid" alt="Colorlib Template">
                                                <div class="text px-4">
                                                    <h3><a href="#">Work Name</a></h3>
                                                    <span>Web Design</span>
                                                </div>
                                            </div>
                                        </div>
                            </div>
                            <div class="item">
                                <div class="project">
                                            <div class="img">
                                                <img src="digilab/images/project-6.jpg" class="img-fluid" alt="Colorlib Template">
                                                <div class="text px-4">
                                                    <h3><a href="#">Work Name</a></h3>
                                                    <span>Web Design</span>
                                                </div>
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                </section>
                    

                <section class="ftco-counter img ftco-section ftco-no-pt ftco-no-pb" id="about-section">
                    <div class="container">
                        <div class="row d-flex">
                            <div class="col-md-6 col-lg-5 d-flex">
                                <div class="img d-flex align-self-stretch align-items-center" style="background-image:url(digilab/images/about-3.jpg);">
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-7 pl-lg-5 py-5">
                                <div class="py-md-5">
                                    <div class="row justify-content-start pb-3">
                                <div class="col-md-12 heading-section ftco-animate">
                                    <span class="subheading">歡迎來到 高禾瓦創意數位</span>
                                    <h2 class="mb-4" style="font-size: 34px; text-transform: capitalize;">最專業的資訊解決方案團隊</h2>
                                    <p>高禾瓦用台語來唸就是“交給我”的意思，希望客戶能把需求安心地交給我們服務。高禾瓦創意數位創立於2017年11月，由一群在業界超過10年經驗的工程師們所組成，我們所具備的專業技術能力與歷練，讓我們能在客戶不同的需求上都能做到完善的規劃與執行。</p>
                                    <p>高禾瓦創意數位提供合適的解決方案，不論是各式網站、活動網頁、EC網站，客製化行動應用程式APP，以及企業所需的各種內部管理系統。也不斷向前投入未來趨勢，物聯網、雲端服務、大數據、AI、區塊鏈等相關服務，能夠為客戶達成不同階段的目標。</p>
                                    <p>高禾瓦已經累積了豐富的服務經驗，曾經替50間以上的公司行號服務，客戶遍及國內外，其中不乏知名上市櫃公司，接觸過的行業類別也是相當廣泛，我們非常期待能夠為您服務。</p>
                                </div>
                                </div>
                            <div class="counter-wrap ftco-animate d-flex mt-md-3">
                            <div class="text p-4 bg-primary">
                                <p class="mb-0">
                                    <span class="number" data-number="10">0</span>
                                    <span>年經驗</span>
                                </p>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>

                <section class="ftco-section bg-light" hidden>
                    <div class="container">
                        <div class="row justify-content-center pb-5">
                    <div class="col-md-6 heading-section text-center ftco-animate">
                        <span class="subheading">About Us</span>
                        <h2 class="mb-4">Our Staff</h2>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia</p>
                    </div>
                    </div>
                    <div class="row">
                                <div class="col-md-6 col-lg-3 ftco-animate">
                                    <div class="staff">
                                        <div class="img-wrap d-flex align-items-stretch">
                                            <div class="img align-self-stretch" style="background-image: url(digilab/images/staff-1.jpg);"></div>
                                        </div>
                                        <div class="text d-flex align-items-center pt-3 text-center">
                                            <div>
                                                <h3 class="mb-2">Lloyd Wilson</h3>
                                                <span class="position mb-4">CEO, Founder</span>
                                                <div class="faded">
                                                    <ul class="ftco-social text-center">
                                        <li class="ftco-animate"><a href="#"><span class="icon-twitter"></span></a></li>
                                        <li class="ftco-animate"><a href="#"><span class="icon-facebook"></span></a></li>
                                        <li class="ftco-animate"><a href="#"><span class="icon-google-plus"></span></a></li>
                                        <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                    </ul>
                                </div>
                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-3 ftco-animate">
                                    <div class="staff">
                                        <div class="img-wrap d-flex align-items-stretch">
                                            <div class="img align-self-stretch" style="background-image: url(digilab/images/staff-2.jpg);"></div>
                                        </div>
                                        <div class="text d-flex align-items-center pt-3 text-center">
                                            <div>
                                                <h3 class="mb-2">Rachel Parker</h3>
                                                <span class="position mb-4">Web Designer</span>
                                                <div class="faded">
                                                    <ul class="ftco-social text-center">
                                        <li class="ftco-animate"><a href="#"><span class="icon-twitter"></span></a></li>
                                        <li class="ftco-animate"><a href="#"><span class="icon-facebook"></span></a></li>
                                        <li class="ftco-animate"><a href="#"><span class="icon-google-plus"></span></a></li>
                                        <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                    </ul>
                                </div>
                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-3 ftco-animate">
                                    <div class="staff">
                                        <div class="img-wrap d-flex align-items-stretch">
                                            <div class="img align-self-stretch" style="background-image: url(digilab/images/staff-3.jpg);"></div>
                                        </div>
                                        <div class="text d-flex align-items-center pt-3 text-center">
                                            <div>
                                                <h3 class="mb-2">Ian Smith</h3>
                                                <span class="position mb-4">Web Developer</span>
                                                <div class="faded">
                                                    <ul class="ftco-social text-center">
                                        <li class="ftco-animate"><a href="#"><span class="icon-twitter"></span></a></li>
                                        <li class="ftco-animate"><a href="#"><span class="icon-facebook"></span></a></li>
                                        <li class="ftco-animate"><a href="#"><span class="icon-google-plus"></span></a></li>
                                        <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                    </ul>
                                </div>
                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-3 ftco-animate">
                                    <div class="staff">
                                        <div class="img-wrap d-flex align-items-stretch">
                                            <div class="img align-self-stretch" style="background-image: url(digilab/images/staff-4.jpg);"></div>
                                        </div>
                                        <div class="text d-flex align-items-center pt-3 text-center">
                                            <div>
                                                <h3 class="mb-2">Alicia Henderson</h3>
                                                <span class="position mb-4">Graphic Designer</span>
                                                <div class="faded">
                                                    <ul class="ftco-social text-center">
                                        <li class="ftco-animate"><a href="#"><span class="icon-twitter"></span></a></li>
                                        <li class="ftco-animate"><a href="#"><span class="icon-facebook"></span></a></li>
                                        <li class="ftco-animate"><a href="#"><span class="icon-google-plus"></span></a></li>
                                        <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                    </ul>
                                </div>
                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </section>

                <section class="ftco-section testimony-section" id="testimony-section" hidden>
                <div class="container">
                    <div class="row justify-content-center pb-3">
                    <div class="col-md-7 text-center heading-section heading-section-white ftco-animate">
                        <h2 class="mb-4">Happy Clients</h2>
                    </div>
                    </div>
                    <div class="row ftco-animate justify-content-center">
                    <div class="col-md-12">
                        <div class="carousel-testimony owl-carousel ftco-owl">
                        <div class="item">
                            <div class="testimony-wrap text-center py-4 pb-5">
                            <div class="user-img" style="background-image: url(digilab/images/person_1.jpg)">
                                <span class="quote d-flex align-items-center justify-content-center">
                                <i class="icon-quote-left"></i>
                                </span>
                            </div>
                            <div class="text px-4 pb-5">
                                <p class="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                                <p class="name">John Fox</p>
                                <span class="position">Businessman</span>
                            </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="testimony-wrap text-center py-4 pb-5">
                            <div class="user-img" style="background-image: url(digilab/images/person_2.jpg)">
                                <span class="quote d-flex align-items-center justify-content-center">
                                <i class="icon-quote-left"></i>
                                </span>
                            </div>
                            <div class="text px-4 pb-5">
                                <p class="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                                <p class="name">John Fox</p>
                                <span class="position">Businessman</span>
                            </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="testimony-wrap text-center py-4 pb-5">
                            <div class="user-img" style="background-image: url(digilab/images/person_3.jpg)">
                                <span class="quote d-flex align-items-center justify-content-center">
                                <i class="icon-quote-left"></i>
                                </span>
                            </div>
                            <div class="text px-4 pb-5">
                                <p class="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                                <p class="name">John Fox</p>
                                <span class="position">Businessman</span>
                            </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="testimony-wrap text-center py-4 pb-5">
                            <div class="user-img" style="background-image: url(digilab/images/person_4.jpg)">
                                <span class="quote d-flex align-items-center justify-content-center">
                                <i class="icon-quote-left"></i>
                                </span>
                            </div>
                            <div class="text px-4 pb-5">
                                <p class="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                                <p class="name">John Fox</p>
                                <span class="position">Businessman</span>
                            </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="testimony-wrap text-center py-4 pb-5">
                            <div class="user-img" style="background-image: url(digilab/images/person_3.jpg)">
                                <span class="quote d-flex align-items-center justify-content-center">
                                <i class="icon-quote-left"></i>
                                </span>
                            </div>
                            <div class="text px-4 pb-5">
                                <p class="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                                <p class="name">John Fox</p>
                                <span class="position">Businessman</span>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section class="ftco-section bg-light" id="blog-section" hidden>
                <div class="container">
                    <div class="row justify-content-center mb-5 pb-5">
                    <div class="col-md-7 heading-section text-center ftco-animate">
                        <span class="subheading">Blog</span>
                        <h2 class="mb-4">Our Blog</h2>
                        <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia</p>
                    </div>
                    </div>
                    <div class="row d-flex">
                    <div class="col-md-4 d-flex ftco-animate">
                        <div class="blog-entry justify-content-end">
                        <a href="single.html" class="block-20" style="background-image: url('digilab/images/image_1.jpg');">
                        </a>
                        <div class="text mt-3 float-right d-block">
                            <div class="d-flex align-items-center pt-2 mb-4 topp">
                                <div class="one mr-2">
                                    <span class="day">12</span>
                                </div>
                                <div class="two">
                                    <span class="yr">2019</span>
                                    <span class="mos">March</span>
                                </div>
                            </div>
                            <h3 class="heading"><a href="single.html">Why Lead Generation is Key for Business Growth</a></h3>
                            <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                            <div class="d-flex align-items-center mt-4 meta">
                                <p class="mb-0"><a href="#" class="btn btn-primary">Read More <span class="ion-ios-arrow-round-forward"></span></a></p>
                                <p class="ml-auto mb-0">
                                    <a href="#" class="mr-2">Admin</a>
                                    <a href="#" class="meta-chat"><span class="icon-chat"></span> 3</a>
                                </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex ftco-animate">
                        <div class="blog-entry justify-content-end">
                        <a href="single.html" class="block-20" style="background-image: url('digilab/images/image_2.jpg');">
                        </a>
                        <div class="text mt-3 float-right d-block">
                            <div class="d-flex align-items-center pt-2 mb-4 topp">
                                <div class="one mr-2">
                                    <span class="day">10</span>
                                </div>
                                <div class="two">
                                    <span class="yr">2019</span>
                                    <span class="mos">March</span>
                                </div>
                            </div>
                            <h3 class="heading"><a href="single.html">Why Lead Generation is Key for Business Growth</a></h3>
                            <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                            <div class="d-flex align-items-center mt-4 meta">
                                <p class="mb-0"><a href="#" class="btn btn-primary">Read More <span class="ion-ios-arrow-round-forward"></span></a></p>
                                <p class="ml-auto mb-0">
                                    <a href="#" class="mr-2">Admin</a>
                                    <a href="#" class="meta-chat"><span class="icon-chat"></span> 3</a>
                                </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex ftco-animate">
                        <div class="blog-entry">
                        <a href="single.html" class="block-20" style="background-image: url('digilab/images/image_3.jpg');">
                        </a>
                        <div class="text mt-3 float-right d-block">
                            <div class="d-flex align-items-center pt-2 mb-4 topp">
                                <div class="one mr-2">
                                    <span class="day">05</span>
                                </div>
                                <div class="two">
                                    <span class="yr">2019</span>
                                    <span class="mos">March</span>
                                </div>
                            </div>
                            <h3 class="heading"><a href="single.html">Why Lead Generation is Key for Business Growth</a></h3>
                            <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                            <div class="d-flex align-items-center mt-4 meta">
                                <p class="mb-0"><a href="#" class="btn btn-primary">Read More <span class="ion-ios-arrow-round-forward"></span></a></p>
                                <p class="ml-auto mb-0">
                                    <a href="#" class="mr-2">Admin</a>
                                    <a href="#" class="meta-chat"><span class="icon-chat"></span> 3</a>
                                </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section class="ftco-section contact-section ftco-no-pb bg-light" id="contact-section">
                <div class="container">
                    <div class="row justify-content-center mb-5 pb-3">
                    <div class="col-md-7 heading-section text-center ftco-animate">
                        <span class="subheading">聯繫</span>
                        <h2 class="mb-4">聯絡我們</h2>
                        <p hidden>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia</p>
                    </div>
                    </div>
                    <div class="row d-flex contact-info mb-5">
                    <div class="col-md-6 col-lg-3 d-flex ftco-animate">
                        <div class="align-self-stretch box p-4 text-center">
                            <div class="icon d-flex align-items-center justify-content-center">
                                <span class="icon-map-signs"></span>
                            </div>
                            <h3 class="mb-4">地址</h3>
                            <p>10491 台北市中山區南京東路二段95號11樓</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 d-flex ftco-animate">
                        <div class="align-self-stretch box p-4 text-center">
                            <div class="icon d-flex align-items-center justify-content-center">
                                <span class="icon-phone2"></span>
                            </div>
                            <h3 class="mb-4">聯絡電話</h3>
                            <p><a href="tel://+886922633224">+886-922-633224</a></p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 d-flex ftco-animate">
                        <div class="align-self-stretch box p-4 text-center">
                            <div class="icon d-flex align-items-center justify-content-center">
                                <span class="icon-paper-plane"></span>
                            </div>
                            <h3 class="mb-4">Email</h3>
                            <p><a href="mailto:contact@gaohowa.com">contact@gaohowa.com</a></p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 d-flex ftco-animate">
                        <div class="align-self-stretch box p-4 text-center">
                            <div class="icon d-flex align-items-center justify-content-center">
                                <span class="icon-globe"></span>
                            </div>
                            <h3 class="mb-4">網站</h3>
                            <p><a href="http://gaohowa.com">http://gaohowa.com</a></p>
                        </div>
                    </div>
                    </div>
                    <div class="row no-gutters block-9">
                    <div class="col-md-6 order-md-last d-flex">
                        <form action="#" class="bg-light p-5 contact-form">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="怎麼稱呼您？" v-model="name" value="${name}">
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="留下您的Email讓我們與您聯繫" v-model="email" value="${email}">
                        </div>
                        <div class="form-group">
                            <input type="tel" class="form-control" placeholder="留下您的電話號碼讓我們與您聯繫" v-model="contact_phone_number" value="${contact_phone_number}">
                        </div>
                        <div class="form-group">
                            <textarea name="" id="" cols="30" rows="7" class="form-control" placeholder="留下訊息" v-model="message" value="${message}"></textarea>
                        </div>
                        <div class="form-group">
                            <input type="button" value="發送" class="btn btn-primary py-3 px-5" v-on:click="sendMessageToPC">
                        </div>
                        </form>
                    
                    </div>

                    <div class="col-md-6 d-flex">
                        <div id="map" class="bg-white">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.461892901173!2d121.53013261449996!3d25.052329343711587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a961242803d5%3A0x25353205793568fb!2zMTA0OTHlj7DljJfluILkuK3lsbHljYDljZfkuqzmnbHot6_kuozmrrU5NeiZnw!5e0!3m2!1szh-TW!2stw!4v1576687722743!5m2!1szh-TW!2stw" frameborder="0" style="border:0;height:100%;width:100%;"  allowfullscreen=""></iframe>
                        </div>
                        
                    </div>
                    </div>
                </div>
                </section>
                    

                <footer class="ftco-footer ftco-section">
                <div class="container">
                    <div class="row mb-5">
                    <div class="col-md">
                        <div class="ftco-footer-widget mb-4">
                        <h2 class="ftco-heading-2">關於 高禾瓦</h2>
                        <p>高禾瓦創意數位有限公司創立於2017年，致力推廣企業資訊化與系統化，並提供最專業的顧問諮詢與技術支援。</p>
                        <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                            <li class="ftco-animate" hidden><a href="#"><span class="icon-twitter"></span></a></li>
                            <li class="ftco-animate"><a href="https://www.facebook.com/gaohowa.digital/" target="_blank"><span class="icon-facebook"></span></a></li>
                            <li class="ftco-animate" hidden><a href="#"><span class="icon-instagram"></span></a></li>
                        </ul>
                        </div>
                    </div>
                    <div class="col-md">
                        <div class="ftco-footer-widget mb-4 ml-md-4">
                        <h2 class="ftco-heading-2">快速導覽</h2>
                        <ul class="list-unstyled">
                            <li><a href="#home-section"><span class="icon-long-arrow-right mr-2"></span>首頁</a></li>
                            <li><a href="#about-section"><span class="icon-long-arrow-right mr-2"></span>關於我們</a></li>
                            <li><a href="#services-section"><span class="icon-long-arrow-right mr-2"></span>服務項目</a></li>
                            <li hidden><a href="#projects-section"><span class="icon-long-arrow-right mr-2"></span>專案經歷</a></li>
                            <li><a href="#contact-section"><span class="icon-long-arrow-right mr-2"></span>聯繫</a></li>
                        </ul>
                        </div>
                    </div>
                    <div class="col-md">
                        <div class="ftco-footer-widget mb-4">
                        <h2 class="ftco-heading-2">服務項目</h2>
                        <ul class="list-unstyled">
                            <li><a href="#services-section" @click="goToService(1)"><span class="icon-long-arrow-right mr-2"></span>網站開發</a></li>
                            <li><a href="#services-section" @click="goToService(2)"><span class="icon-long-arrow-right mr-2"></span>手機應用程式</a></li>
                            <li><a href="#services-section" @click="goToService(3)"><span class="icon-long-arrow-right mr-2"></span>企業系統規劃</a></li>
                            <li><a href="#services-section" @click="goToService(4)"><span class="icon-long-arrow-right mr-2"></span>Hardware & Software 整合</a></li>
                            <li><a href="#services-section" @click="goToService(5)"><span class="icon-long-arrow-right mr-2"></span>雲端伺服器技術與建置</a></li>
                        </ul>
                        </div>
                    </div>
                    <div class="col-md">
                        <div class="ftco-footer-widget mb-4">
                            <h2 class="ftco-heading-2">聯繫資訊</h2>
                            <div class="block-23 mb-3">
                            <ul>
                                <li><a href="https://goo.gl/maps/boknh8Mkik4m8jB5A"><span class="icon icon-map-marker"></span><span class="text">10491 台北市中山區南京東路二段95號11樓</span></a></li>
                                <li><a href="tel://+886922633224"><span class="icon icon-phone"></span><span class="text">+886-922-633224</span></a></li>
                                <li><a href="mailto:contact@gaohowa.com"><span class="icon icon-envelope"></span><span class="text">contact@gaohowa.com</span></a></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-12 text-center">

                        <p><!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
            Copyright &copy;{{curYear}} All rights reserved | This template is made with <i class="icon-heart color-danger" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib</a>
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --></p>
                    </div>
                    </div>
                </div>
                </footer>
                
            

            <!-- loader -->
            <div id="ftco-loader" class="show fullscreen" ref="loading_show"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/></svg></div>
        </body>
    </div>

</template>

<script>

export default {
    name: 'Index',
    data : function() {
        return {
            curYear: new Date().getFullYear(),
            name: '',
            message: '',
            email: '',
            contact_phone_number: ''
        }  
    },
    props: {
        data: {
            name: String,
            contact_phone_number: String,
            message: String,
            email: String
        } 
    },
    methods: {
        goToService: function(index) {
            let name = 'service-tab' + index;
            let action = this.$refs[name];
            action.click();
        },
        sendMessageToPC: function() {
            var _this = this;
            var name = _this.name;
            var phone = _this.contact_phone_number;
            var email = _this.email;
            var message = _this.message;

            var bodyContent = '客戶名稱: ' + name + '</br>' +
            '聯絡電話: ' + phone + '</br>' + 
            'E-mail: ' + email + '</br>' + 
            '留言: ' + message;

            var loading = _this.$refs['loading_show'];
            loading.classList.add('show');

            _this.Email.send({
                SecureToken : 'f38efbed-181f-4b7b-b1d3-c3b292f4011a',
                // SecureToken : '672311fd-b6c1-4b3e-8505-63d644a2ede8',
                To : 'contact@gaohowa.com',
                From : 'gaohowa.office@gmail.com',
                Subject : '您有一封來自 Gaohowa 的業務聯絡單',
                Body : bodyContent
            }).then(() => {  
                alert('感謝您，我們將儘速與您聯絡，謝謝！');
                _this.name = '';
                _this.email = '';
                _this.message = '';
                _this.contact_phone_number = '';
                loading.classList.remove('show');
            }).catch(() => {
                alert('忙碌中，請稍後再試...');
                loading.classList.remove('show');
            }).finally(() => {
    
            });
        }
    },
    head: {
        title: () => {
            return {
				inner: '高禾瓦創意數位有限公司'
			}
		},
		meta: [
        ],
        script: [
            // {src:'/digilab/js/jquery.min.js'},
            // {src:'/digilab/js/jquery-migrate-3.0.1.min.js'},
            // {src:'/digilab/js/popper.min.js'},
            // {src:'/digilab/js/bootstrap.min.js'},
            // {src:'/digilab/js/jquery.easing.1.3.js'},
            // {src:'/digilab/js/jquery.waypoints.min.js'},
            // {src:'/digilab/js/jquery.stellar.min.js'},
            // {src:'/digilab/js/owl.carousel.min.js'},
            // {src:'/digilab/js/jquery.magnific-popup.min.js'},
            // {src:'/digilab/js/aos.js'},
            // {src:'/digilab/js/jquery.animateNumber.min.js'},
            // {src:'/digilab/js/scrollax.min.js'},
            // {src:'https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false'},
            // {src:'/digilab/js/google-map.js'},
            // {src:'/digilab/js/main.js'}
        ],
        link: [
			{rel:'stylesheet', href: 'https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800,900'},
            {rel:"stylesheet", href:"/digilab/css/open-iconic-bootstrap.min.css"},
            {rel:"stylesheet", href:"/digilab/css/animate.css"},
            {rel:"stylesheet", href:"/digilab/css/owl.carousel.min.css"},
            {rel:"stylesheet", href:"/digilab/css/owl.theme.default.min.css"},
			{rel:"stylesheet", href:"/digilab/css/magnific-popup.css"},
            {rel:"stylesheet", href:"/digilab/css/aos.css"},
            {rel:"stylesheet", href:"/digilab/css/ionicons.min.css"},
            {rel:"stylesheet", href:"/digilab/css/flaticon.css"},
            {rel:"stylesheet", href:"/digilab/css/icomoon.css"},
            {rel:"stylesheet", href:"/digilab/css/style.css"}
        ]
    }
}
</script>

